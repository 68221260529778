<template>
  <div>
    <base-page
      title="Reference indices"
      :breadcrumbs="breadcrumbs"
      call-to-action-text="Add index"
      call-to-action-permission="insurance.reference-indices.store"
      @call-to-action="dialog = true"
    >
      <template #content>
        <reference-index-list
          @edit="edit"
        />
      </template>
    </base-page>

    <reference-index-form
      v-if="dialog"
      :reference-index="referenceIndex"
      :dialog="dialog"
      @close="close()"
    />
  </div>
</template>

<script>
export default {
  components: {
    'reference-index-list': () => import('@/components/insurance/ReferenceIndexList.vue'),
    'reference-index-form': () => import('@/components/insurance/ReferenceIndexForm.vue'),
  },

  data () {
    return {
      dialog: false,
      referenceIndex: null,
      breadcrumbs: [
        { text: 'Reference indices', disabled: true, },
      ],
    }
  },

  methods: {
    close () {
      this.dialog = false
    },

    edit (referenceIndex) {
      this.referenceIndex = referenceIndex
      this.dialog = true
    }
  }
} 
</script>